<template>
  <div>
    <requirement-list></requirement-list>
  </div>
</template>

<script>
import RequirementList from "../components/tracking/RequirementList";
export default {
  components: { RequirementList }
};
</script>
