<!-- main track requirement -->
<!-- import : 
//AddIssueButton
//RequirementCard
//RequirementDetails
//RequirementSearch
-->
<template>
  <div>
    <requirement-search @filtered="requirements = $event"></requirement-search>
    <add-issue-button v-if="checkRole"></add-issue-button>
    <requirement-card
      v-for="requirement in requirements"
      :requirement="requirement"
      :key="`r-${requirement.number}`"
    >
      <requirement-details :requirement="requirement"></requirement-details>
    </requirement-card>
  </div>
</template>

<script>
import AddIssueButton from "./AddIssueButton.vue";
import RequirementCard from "./RequirementCard.vue";
import RequirementDetails from "./RequirementDetails.vue";
import RequirementSearch from "./RequirementSearch.vue";

import { mapGetters } from "vuex";

export default {
  name: "RequirementList",
  data: function() {
    return {
      requirements: []
    };
  },
  computed: {
    ...mapGetters("auth", ["studies"]),
    checkRole() {
      const roles = ["developer", "administrator", "studycoordinator"];
      // eslint-disable-next-line no-unused-vars
      return Object.entries(this.studies).reduce((res, [key, value]) => {
        if (roles.some(r => value.role.startsWith(r))) return true;
        return res;
      }, false);
    }
  },
  components: {
    RequirementCard,
    RequirementDetails,
    RequirementSearch,
    AddIssueButton
  }
};
</script>
