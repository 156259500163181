<template>
  <div>
    <v-card elevation="5" class="rounded-pill mx-20 my-10">
      <v-card-text>
        <v-text-field
          id="requirement-search"
          hide-details="auto"
          class="pt-0 ml-10 mr-20"
          clearable
          :value="query"
          :label="$t('label')"
          @input="search($event)"
        >
          <template slot="prepend">
            <v-icon>mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-card-text>
    </v-card>
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      class="my-5"
    ></v-progress-linear>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      query: undefined,
      requirements: [],
      current: undefined,
      isLoading: true
    };
  },
  methods: {
    search(query, delay = 300) {
      this.isLoading = true;
      clearTimeout(this.current);
      this.query = query;
      this.current = setTimeout(async () => {
        const q = query ? `track/requirement?q=${query}` : "track/requirement";
        this.requirements = await this.drivers.client.get(q).json();
        this.updateFilter(query);
        this.isLoading = false;
      }, delay);
    },
    updateFilter(change) {
      if (change == "") change = undefined;
      this.updateQuery({ search: change });
      this.sendFilter();
    },
    updateQuery(change) {
      this.$router.replace({ query: change }).catch(error => {
        if (error.name !== "NavigationDuplicated") console.log(error);
      });
    },
    sendFilter() {
      this.$emit("filtered", this.requirements);
    }
  },
  mounted() {
    this.search(this.$route?.query.search ?? "", 0);
  },
  i18n: {
    messages: {
      en: { label: "Search for requirements" },
      fr: { label: "Rechercher des exigences" }
    }
  }
};
</script>

<style></style>
