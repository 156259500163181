<template>
  <v-btn :color="color" text @click.stop.prevent="$emit('track', component)">
    <v-icon>
      {{ show == component ? "mdi-chevron-up" : "mdi-chevron-down" }}
    </v-icon>
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: ["show", "component"],
  computed: {
    text() {
      return this.component == "test"
        ? "Tests"
        : this.component == "bug"
        ? "Bugs"
        : this.component == "enhancement"
        ? this.$t("enhancements")
        : this.component;
    },
    color() {
      return this.component == "test"
        ? "success"
        : this.component == "bug"
        ? "warning"
        : this.component == "enhancement"
        ? "info"
        : "dark";
    }
  },
  i18n: {
    messages: {
      en: { enhancements: "ENHANCEMENTS" },
      fr: { enhancements: "AMÉLIORATIONS" }
    }
  }
};
</script>
