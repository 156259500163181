<template>
  <v-card class="my-3">
    <v-card-title>
      <span>#{{ requirement.number }} - {{ requirement.title }}</span>
      <span class="col text-right py-0">
        <v-chip color="info" v-if="release">{{ release }}</v-chip>
      </span>
    </v-card-title>
    <v-card-text>
      <fill-rate
        :value="value"
        :color="color"
        :text="name"
        :height="2"
      ></fill-rate>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import FillRate from "../form/FillRate.vue";

export default {
  props: ["requirement"],
  computed: {
    r() {
      if (this.requirement.state == "closed")
        return { value: 1, color: "68C839", name: "Finished" };
      const label = this.requirement.labels.find(l => l.name != "requirement");
      if (!label) return { value: 0, color: "5319E7", name: "Submitted" };
      if (label.name == "Tested") label.value = 0.95;
      if (label.name == "Need test") label.value = 0.9;
      if (label.name == "Tested with NC") label.value = 0.8;
      if (label.name == "PR") label.value = 0.7;
      if (label.name == "Done") label.value = 0.6;
      if (label.name == "On going") label.value = 0.3;
      if (label.name == "wontfix")
        return { value: 1, color: "86B1EF", name: "Won't fix" };
      return label;
    },
    value() {
      return this.r.value;
    },
    color() {
      return this.r.color;
    },
    name() {
      return this.r.name;
    },
    release() {
      return this.requirement.release?.name;
    }
  },
  components: {
    FillRate
  }
};
</script>
