<template>
  <div
    class="activate mb-4 v-alert--outlined rounded"
    :class="`${state}--text`"
  >
    <v-alert
      text
      :type="state"
      :icon="icon"
      dense
      class="mb-0"
      :class="{ 'm-1': dense, 'py-0': dense, 'px-1': dense }"
      @click.stop.prevent="active = !active"
    >
      {{ item.title }}
      <v-icon :class="`${state}--text`">
        {{ active ? "mdi-chevron-up" : "mdi-chevron-down" }}
      </v-icon>
    </v-alert>
    <div v-if="components && components.length > 0" class="my-0 mx-10">
      <track-list
        :requirement="item"
        :show="active"
        :components="components"
        dense
      >
        <template v-slot:empty>No test</template>
      </track-list>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "dense", "components"],
  data() {
    return {
      active: false
    };
  },
  computed: {
    state() {
      return this.item.state == "closed" ? "success" : "warning";
    },
    icon() {
      return this.item.state == "closed" ? "mdi-wrench" : "mdi-progress-wrench";
    }
  },
  components: {
    TrackList: () => import("./TrackList.vue")
  }
};
</script>

<style>
.activate {
  cursor: pointer;
}
</style>
