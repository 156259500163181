<template>
  <div>
    <v-card>
      <div class="my-1 py-1">
        <track-button :show="show" component="test" @track="track">
        </track-button>
        <track-button :show="show" component="bug" @track="track">
        </track-button>
        <track-button :show="show" component="enhancement" @track="track">
        </track-button>
      </div>
      <v-expand-transition>
        <div>
          <v-divider v-show="show"></v-divider>
          <v-card-text v-show="show">
            <track-list
              :show="show"
              :components="['test']"
              :requirement="requirement"
            >
              <template v-slot:empty>No test</template>
            </track-list>
            <track-list
              :show="show"
              :components="['enhancement', 'test']"
              :requirement="requirement"
            >
              <template v-slot:empty>No enhancement</template>
            </track-list>
            <track-list
              :show="show"
              :components="['bug', 'test']"
              :requirement="requirement"
            >
              <template v-slot:empty>No bug</template>
            </track-list>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import TrackButton from "./TrackButton.vue";
import TrackList from "./TrackList.vue";

export default {
  data() {
    return {
      bugs: false,
      tests: false,
      show: undefined
    };
  },
  props: ["requirement"],
  methods: {
    async track(component) {
      this.show = this.show != component ? component : undefined;
    }
  },
  components: {
    TrackButton,
    TrackList
  }
};
</script>
