<template>
  <div class="d-flex align-end flex-column">
    <v-btn id="btn-add-issue" class="mb-3" color="info" @click="newIssue()">
      {{ $t("track.newIssue") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    newIssue() {
      this.$router.push({
        name: "Create issue"
      });
    }
  }
};
</script>
