<template>
  <div v-if="display && items">
    <div v-if="items.length > 0">
      <component
        :is="viewer"
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :dense="dense"
        :components="subComponents"
      >
      </component>
    </div>
    <div v-else-if="items.length == 0">
      <v-alert
        dense
        type="info"
        text
        :class="{ 'm-1': dense, 'py-0': dense, 'px-1': dense }"
      >
        <slot name="empty"></slot>
      </v-alert>
    </div>
  </div>
</template>

<script>
import TrackViewer from "./TrackViewer.vue";
import TestViewer from "./TestViewer.vue";

export default {
  props: {
    requirement: Object,
    show: [String, Boolean],
    components: [String, Array],
    dense: Boolean
  },
  data() {
    return {
      items: undefined
    };
  },
  computed: {
    viewer() {
      return this.component == "test" ? TestViewer : TrackViewer;
    },
    display() {
      return this.show === true || this.show === this.component;
    },
    //label 
    component() {
      return typeof this.components == "string"
        ? this.components
        : this.components[0];
    },
    subComponents() {
      return typeof this.components == "string" ? [] : this.components.slice(1);
    }
  },
  watch: {
    async show() {
      if (this.display && typeof this.items == "undefined") {
        this.items = await this.drivers.client
          .get(`track/requirement/${this.requirement.number}/${this.component}`)
          .json()
          .catch(() => []);
      }
    }
  }
};
</script>
