<template>
  <div>
    <v-alert
      text
      :outlined="!dense"
      type="success"
      icon="mdi-check"
      dense
      :class="{ 'm-1': dense, 'py-0': dense, 'px-1': dense }"
    >
      {{ item.title }}
      <v-icon
        v-if="item.type == 'integration'"
        name="badge-integration-test"
        class="mx-2"
        color="success"
        >mdi-gesture-tap</v-icon
      >
    </v-alert>
  </div>
</template>

<script>
export default {
  props: ["item", "dense"]
};
</script>

<style>
.badge-integration-test {
  z-index: 1;
}
</style>
